@import (less) "mixins.less";

/*************FORMS************/
@labelColor: #666;
@inputColor: #bfbfbf;
@inputBG: #fff;
@inputBorder: @content;
@inputFocusBorder: #666;
@inputFocusBG: #fff;
@errorBG: #bfbfbf;
@errorBorder: #f10e0e;
@errorLabel: #f10e0e;

.gform_wrapper{
            .bodyfont()!important;
            width:100%!important;
            margin-top:0!important;
            label{
                display:none!important;
                }
            .ginput_complex label{
                font-size:12px!important;
                }
            ::-webkit-input-placeholder {
                       color: #fff;
                    }
                    
                    :-moz-placeholder { /* Firefox 18- */
                       color: #fff;  
                    }
                    
                    ::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff;  
                    }
                    
                    :-ms-input-placeholder {  
                       color: #fff;  
                    }
            input[type=text], textarea{
                border:2px solid #fff;
                padding:8px!important;
                width:100%!important;
                
               .muli(18px)!important;
                background:transparent;
                outline:none;
                .transition();
                color:#fff!important;
                }
            input[type=text]:focus, textarea:focus{
                background:rgba(255,255,255,0.5);
                color:@content!important;
                }
            
            select{
                width:100%!important;
                }
            ul.gform_fields{
                padding:0px 0 10px 0!important;
                margin:0!important;
                li{
                    padding-left:0!important;
                    padding-bottom:3px!important;
                    background:none!important;
                    margin-bottom:0!important;
                    }
                li.gfield_error{
                    border:none!important;
                    background:transparent!important;
                    padding:10px!important;
                    input[type=text],textarea{
                    	border:1px solid @aqua!important;
                    	}
                    .validation_message{color:@aqua!important;}
                    }
                }
            }
.validation_error{
    color:@aqua!important;
    border:none!important;
    }
.gform_footer{
    margin-top:0!important;
    padding-top:0!important;
    text-align:center;
    input[type=submit]{
        
        &:hover{
            cursor:pointer;
            }
        }
    }
#gforms_confirmation_message{
    .bodyfont();
    padding:20px;
    border:5px solid #fff;
    text-align:center;
    }
    
.gform_wrapper.two-column_wrapper {
	max-width: 100%;
}

.gform_wrapper.two-column_wrapper ul.gform_fields,
.gform_wrapper.two-column_wrapper ul.gform_column li.gsection:first-child {
	display: none;
}

.gform_wrapper.two-column_wrapper ul.gform_fields.gform_column {
	display: block;
	float:left;
}

.gform_wrapper.two-column_wrapper ul.one-half {
	margin-left: 6% !important;
	width: 47%;
}
.gform_wrapper.two-column_wrapper ul.one-half.first {
	margin-left: 0 !important;
}


.gform_wrapper .gsection {
	border-bottom: none !important;
}

.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
	padding: 0 !important;
}

@media only screen and (max-width: 1075px) {
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		width: 100%;
	}
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		margin-left: 0 !important;
	}
	
	.gform_wrapper .gsection {
		display: none !important; /* remove !important if it's not needed */
	}
	
	#gform_wrapper_1 li {
		margin-bottom: 10px;
	}
	
}